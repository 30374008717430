(function () {
  'use strict';

  angular
  .module('neo.home.refereeNewInvoice')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.refereeNewInvoice', {
      url: '/referee-new-invoice/{licenseId}/inv',
     
          templateUrl: 'home/referee-new-invoice/referee-new-invoice.tpl.html',
          controller: 'RefereeNewInvoiceCtrl',
          controllerAs: 'vm'
        ,
      resolve:{
       
        refereeLicense: function (RefereeLicence,  $stateParams){
          return RefereeLicence.get({licenceId :  $stateParams.licenseId}).$promise;
    },
      InvoiceRef: function (InvoiceRef,  $stateParams){
          return InvoiceRef.query({Id :  $stateParams.licenseId}).$promise;
    },
    refId:function ($stateParams){
        return $stateParams.licenseId;
    }
      }
    });
  }
}());


